<template>
  <div>
    <p class="title">
      <!-- <span class="title-no">{{ index + 1 }}. </span> -->
      <span>{{ item.title }}</span>
    </p>
    <div
      class="option"
      v-for="(t, i) in item.options"
      :key="i"
    >
      <div
        @click="chooses(item, i)"
        style="border-radius: 0"
        :class="
          choosesFlag(item, i)
            ? 'option-uncheckout'
            : disable
            ? 'option-reviewcheckout'
            : 'option-checkout'
        "
      ></div>
      <p
        class="text"
        v-html="t.subOption"
      ></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: NaN
    },
    review: {
      type: Boolean,
      default: false
    },
    // 1为实名 2为匿名
    type: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => []
    },
    questionnaireShow: {
      type: String,
      default: ''
    }
  },
  created() {},
  computed: {
    // 判断多选是否在选中状态
    choosesFlag() {
      return function(item, i) {
        if (item.subAnswer instanceof Array) {
          for (const t of item.subAnswer) {
            if (t == i) {
              return false;
            }
          }
        }
        return true;
      };
    },
    disable() {
      if (this.review || !this.item.isShow) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 多选
    chooses(item, i) {
      if (this.disable) {
        return;
      }
      if (!item.subAnswer) {
        item.subAnswer = [];
      }
      if (!this.choosesFlag(item, i)) {
        item.subAnswer = item.subAnswer.filter(element => {
          return element != i;
        });
      } else {
        item.subAnswer.push(i);
      }
      if (this.type == 2 && this.questionnaireShow == '01') {
        if (this.index + 1 < this.list.length) {
          this.list[this.index + 1].isShow = true;
          this.$emit('edit');
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.option {
  margin-top: 120px;
  > div {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }
  &-checkout {
    background-color: #ffffff;
    border: #ea5514 18px solid;
  }
  &-uncheckout {
    background: #f7f7f7;
    border: 2px solid #666666;
  }
  &-reviewcheckout {
    background-color: #ffffff;
    border: #b5b5b6 18px solid;
  }
  .text {
    width: 900px;
    display: inline-block;
    vertical-align: top;
    font-size: 3rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #646566;
    margin-left: 40px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}
.title {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
  line-height: 112px;
  white-space:pre-wrap

}
</style>
