<!-- 填空题 -->
<template>
  <div>
    <p class="title">
      <span>
        <span v-for="(t, i) in titleArray" :key="i">
          {{ t }}
          <span v-if="i != titleArray.length - 1">
            <input v-model="item.subAnswer[i]" :disabled="disable" @keyup="keyup(i)" @blur="blur(i)" class="title-input">
          </span>
        </span>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      regex: /\^\{(.+?)\}\^/,
      matches: [],
      hintIndex: null,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => { }
    },
    index: {
      type: Number,
      default: NaN
    },
    review: {
      type: Boolean,
      default: false
    },
    // 1为实名 2为匿名
    type: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => []
    },
    questionnaireShow: {
      type: String,
      default: ''
    }
  },
  created() { },
  computed: {
    disable() {
      if (this.review || !this.item.isShow) {
        return true;
      } else {
        return false;
      }
    },
    titleArray() {
      // 匹配出填空项
      let regex = /\^\{[^}]*\}\^/g
      let matches = this.item.title.match(regex);
      this.matches = matches
      // 根据填空项目把题目分割
      let arr = matches.reduce(function (acc, separator) {
        return acc.flatMap(function (segment) {
          return segment.split(separator);
        });
      }, [this.item.title]);
      return arr
    }
  },
  methods: {
    keyup(i) {
      // 匹配'^{....}^'的内容 即输入框类型
      let regex = /\^\{(.+?)\}\^/;
      let type = this.matches[i].match(regex)[1].split(':')[0] //输入框类型
      // 整数类型
      if (type == 'int') {
        let str = this.item.subAnswer[i].replace(/\D/g, "");
        this.$set(this.item.subAnswer, i, str)
        this.hintIndex = i
      } else if (type == 'fractional') {
        // 小数
        let str = this.item.subAnswer[i].replace(/[^0-9.]/g, "");
        if (str.split('.').length > 2) {
          str = str.split('.')[0] + '.' + str.split('.')[1]
        }
        this.$set(this.item.subAnswer, i, str)
        this.hintIndex = i
      }

      if (this.type == 2 && this.questionnaireShow == '01') {
        if (this.index + 1 < this.list.length) {
          this.list[this.index + 1].isShow = true;
          this.$emit('edit');
        }
      }
    },
    blur(i) {
      // 匹配'^{....}^'的内容 即输入框类型
      let regex = /\^\{(.+?)\}\^/;
      let type = this.matches[i].match(regex)[1].split(':')[0] //输入框类型
      let length = this.matches[i].match(regex)[1].split(':')[1] //限制输入的长度 目前仅小数有效
      if (type == 'fractional' && this.item.subAnswer[i]) {
        let str = this.item.subAnswer[i].replace(/[^0-9.]/g, "");
        if (!str.split('.')[1]) {
          // 没有小数点补满
          if (str[str.length - 1] != '.') {
            str += '.'
          }
          for (let n = 0; n < length; n++) {
            str += '0'
          }
        } else if (str.split('.')[1].length < length) {
          // 长度不足则补充
          for (let n = str.split('.')[1].length; n < length; n++) {
            str += '0'
          }
        } else if (str.split('.')[1] && str.split('.')[1].length > length) {
          // 长度超出则删除
          str = str.split('.')[0] + '.' + str.split('.')[1].substring(0, length)
        }
        this.$set(this.item.subAnswer, i, str)
      }
      this.hintIndex = null
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
  line-height: 112px;
  white-space:pre-wrap !important;

  &-space {
    padding: 0 10px;
    width: 180px;
    border-bottom: solid black 2px;
    display: inline-block;
    text-align: center;
  }

  &-input {
    width: 180px;
    border: none;
    border-bottom: solid black 2px;
    text-align: center;
    border-radius: 0 !important;
  }
}

.input {
  width: 800px;
  height: 135px;
  background: #f7f7fa;
  border: 4px solid #b5b5b6;
  border-radius: 16px;
  margin-top: 120px;
  margin-left: 25px;
  font-size: 48px;
}

.inputkey {
  font-size: 54px;
}

.hint {
  font-size: 35px;
  margin-left: 100px;
  color: red;
}
</style>
