<template>
  <div>
    <p class="title">
      <!-- <span class="title-no">{{ index + 1 }}. </span> -->
      <span>{{ item.title }}</span>
    </p>
    <!-- maxlength="50" -->
    <textarea
      v-model="item.subAnswer"
      :disabled="disable"
      class="area"
      :rows="rows"
      onscroll="this.rows+=1"
      :style="{
        'background-color': disable ? '#f7f7fa' : 'white',
      }"
      @focus="focus"
      @blur="blur"
      @keydown="keydown"
    />
    </div>
</template>
<script>
export default {
  data() {
    return {
      rows:1
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: NaN
    },
    review: {
      type: Boolean,
      default: false
    },
    // 1为实名 2为匿名
    type: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => []
    },
    questionnaireShow: {
      type: String,
      default: ''
    }
  },
  created() {},
  computed: {
    disable() {
      if (this.review || !this.item.isShow) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 输入
    keydown() {
      if (this.type == 2 && this.questionnaireShow == '01') {
        if (this.index + 1 < this.list.length) {
          this.list[this.index + 1].isShow = true;
          this.$emit('edit');
        }
      }
    },
    // 获取焦点
    focus(){
      if (this.type == 1) {
        this.rows = 4
      }
    },
    // 失去焦点
    blur(){
      
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
  line-height: 112px;
  white-space:pre-wrap

}
.area {
  width: 930px;
  resize:none;
  background: #f7f7fa;
  border: 2px solid #b5b5b6;
  border-radius: 16px;
  margin-top: 60px;
  font-size: 48px;
  line-height: 120px;
}
</style>
