<template>
  <div>
    <p class="title">
      <!-- <span class="title-no">{{ index + 1 }}. </span> -->
      <span>{{ item.title }}</span>
    </p>
    <div class="option" v-for="(t, i) in item.options" :key="i">
      <div @click="choose(i)" :class="item.subAnswer !== i
        ? 'option-uncheckout'
        : review
          ? 'option-reviewcheckout'
          : 'option-checkout'
        "></div>
      <p class="text" v-html="t.subOption"></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: () => { }
    },
    index: {
      type: Number,
      default: NaN
    },
    review: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    // 1为实名 2为匿名
    type: {
      type: Number,
      default: 1
    },
    questionnaireShow: {
      type: String,
      default: ''
    }
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted() {
    if (typeof (this.item.subAnswer) == 'number') {
      this.choose(this.item.subAnswer)
    }
  },
  computed: {},
  methods: {
    choose(i) {
      // 当前答案的跳转项
      let currentSkip = this.item.subAnswer != null ? this.item.options[this.item.subAnswer].subOptionSkip : null
      if (this.review) {
        return;
      }
      this.item.subAnswer = i;
      // 开始下标
      let start = this.index + 1;

      if (this.type == 1) {
        //  实名
        // 更改选项时候取消跳转
        if (currentSkip) {
          let end = currentSkip;
          if (end == 'end') {
            end = this.list.length
          }
          for (let n = start; n < end; n++) {
            this.$set(this.list[n], 'isShow', true);
          }
        }

        // 存在新选项的跳转
        if (this.item.options[i].subOptionSkip == 'end') {
          // 直接结束
          for (let n = start; n < this.list.length; n++) {
            this.$set(this.list[n], 'isShow', false);
          }
        } else if (this.item.options[i].subOptionSkip - 1 > 0) {
          // 跳转其他题目
          let end = this.item.options[i].subOptionSkip - 1;
          if (end > this.list.length) {
            end = this.list.length;
          }
          for (let n = start; n < end; n++) {
            this.$set(this.list[n], 'isShow', false);
          }
        }
      } else if (this.type == 2 && this.questionnaireShow == '01') {
        // 匿名
        for (let index = this.index + 1; index < this.list.length; index++) {
          this.list[index].isShow = false;
        }
        if (this.item.options[i].subOptionSkip) {
          // 有跳转选项
          if (this.item.options[i].subOptionSkip == 'end') {
            // 立即结束答题
            this.$emit('end');
          } else {
            this.list[this.item.options[i].subOptionSkip - 1].isShow = true;
          }
        } else {
          // 下一题
          if (this.index + 1 < this.list.length) {
            this.list[this.index + 1].isShow = true;
          }
        }
        this.$emit('edit');
      }
    }
  }
};
</script>
<style lang="less" scoped>
.option {
  margin-top: 120px;

  >div {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }

  &-checkout {
    background-color: #ffffff;
    border: #ea5514 18px solid;
  }

  &-uncheckout {
    background: #f7f7f7;
    border: 2px solid #666666;
  }

  &-reviewcheckout {
    background-color: #ffffff;
    border: #b5b5b6 18px solid;
  }

  .text {
    width: 900px;
    display: inline-block;
    vertical-align: top;
    font-size: 3rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #646566;
    margin-left: 40px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}

.title {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
  line-height: 112px;
  white-space:pre-wrap

}
</style>
