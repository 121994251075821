import { render, staticRenderFns } from "./smallBlank.vue?vue&type=template&id=326b4e06&scoped=true"
import script from "./smallBlank.vue?vue&type=script&lang=js"
export * from "./smallBlank.vue?vue&type=script&lang=js"
import style0 from "./smallBlank.vue?vue&type=style&index=0&id=326b4e06&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326b4e06",
  null
  
)

export default component.exports