<!-- 单选填空 -->
<template>
  <div>
    <p class="title">
      <!-- <span class="title-no">{{ index + 1 }}. </span> -->
      <span>{{ item.title }}</span>
    </p>
    <div
      class="option"
      v-for="(t, i) in item.options"
      :key="i"
    >
      <div
        @click="choose(i)"
        :class="
          item.subAnswer !== i
            ? 'option-uncheckout'
            : disable
            ? 'option-reviewcheckout'
            : 'option-checkout'
        "
      ></div>
      <p
        class="text"
        v-html="t.subOption"
      ></p>
      <br />
      <textarea
        @blur="blurInput(t)"
        @focus="focusInput(t)"
        v-if="t.subOptionFill == 'true'"
        v-model="t.supplement"
        type="text"
        :class="['area1', { area2: t.focus }]"
        :style="{
          'background-color':
            disable || subOptionFillDisabled(item, i) ? '#f7f7fa' : 'white',
        }"
        :disabled="disable || subOptionFillDisabled(item, i)"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: NaN
    },
    review: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    // 1为实名 2为匿名
    type: {
      type: Number,
      default: 1
    },
    questionnaireShow: {
      type: String,
      default: ''
    }
  },
  created() {},
  mounted() {
    if (typeof (this.item.subAnswer) == 'number') {
      this.choose(this.item.subAnswer)
    }
  },
  computed: {
    subOptionFillDisabled() {
      return (item, i) => {
        if (item.subAnswer != undefined) {
          if (item.subAnswer == i) {
            return false;
          }
        }
        return true;
      };
    },
    disable() {
      if (this.review || !this.item.isShow) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    choose(i) {
      // 当前答案的跳转项
      let currentSkip = this.item.subAnswer != null ? this.item.options[this.item.subAnswer].subOptionSkip : null
      if (this.review) {
        return;
      }
      this.item.subAnswer = i;
      // 开始下标
      let start = this.index + 1;

      if (this.type == 1) {
        //  实名
        // 更改选项时候取消跳转
        if (currentSkip) {
          let end = currentSkip;
          for (let n = start; n < end; n++) {
            this.list[n].isShow = true;
          }
        }

        // 存在新选项的跳转
        if (this.item.options[i].subOptionSkip - 1 > 0) {
          let end = this.item.options[i].subOptionSkip - 1;
          if (end > this.list.length) {
            end = this.list.length;
          }
          for (let n = start; n < end; n++) {
            this.list[n].isShow = false;
          }
        }
      } else if (this.type == 2 && this.questionnaireShow == '01'){
        // 匿名
        for (let index = this.index + 1; index < this.list.length; index++) {
          this.list[index].isShow = false;
        }
        if (this.item.options[i].subOptionSkip) {
          // 有跳转选项
          if (this.item.options[i].subOptionSkip == 'end') {
            // 立即结束答题
            this.$emit('end');
          } else {
            this.list[this.item.options[i].subOptionSkip - 1].isShow = true;
          }
        } else {
          // 下一题
          if (this.index + 1 < this.list.length) {
            this.list[this.index + 1].isShow = true;
          }
        }
        this.$emit('edit');
      }
    },
    focusInput(item) {
      this.$set(item, 'focus', true);
    },
    blurInput(item) {
      this.$set(item, 'focus', false);
    }
  }
};
</script>
<style lang="less" scoped>
.option {
  margin-top: 120px;
  > div {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }
  &-checkout {
    background-color: #ffffff;
    border: #ea5514 18px solid;
  }
  &-uncheckout {
    background: #f7f7f7;
    border: 2px solid #666666;
  }
  &-reviewcheckout {
    background-color: #ffffff;
    border: #b5b5b6 18px solid;
  }
  .text {
    width: 900px;
    display: inline-block;
    vertical-align: top;
    font-size: 3rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #646566;
    margin-left: 40px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}
.title {
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
  line-height: 112px;
  white-space:pre-wrap

}
.area1 {
  width: 900px;
  height: 135px;
  background: #f7f7fa;
  border: 2px solid #b5b5b6;
  border-radius: 16px;
  margin-top: 50px;
  margin-left: 25px;
  font-size: 48px;
  line-height: 135px;
  resize: none;
}
.area2 {
  height: 500px;
}
</style>
