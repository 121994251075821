var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.item.title))])]),_vm._l((_vm.item.options),function(t,i){return _c('div',{key:i,staticClass:"option"},[_c('div',{class:_vm.item.subAnswer !== i
          ? 'option-uncheckout'
          : _vm.disable
          ? 'option-reviewcheckout'
          : 'option-checkout',on:{"click":function($event){return _vm.choose(i)}}}),_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(t.subOption)}}),_c('br'),(t.subOptionFill == 'true')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(t.supplement),expression:"t.supplement"}],class:['area1', { area2: t.focus }],style:({
        'background-color':
          _vm.disable || _vm.subOptionFillDisabled(_vm.item, i) ? '#f7f7fa' : 'white',
      }),attrs:{"type":"text","disabled":_vm.disable || _vm.subOptionFillDisabled(_vm.item, i)},domProps:{"value":(t.supplement)},on:{"blur":function($event){return _vm.blurInput(t)},"focus":function($event){return _vm.focusInput(t)},"input":function($event){if($event.target.composing)return;_vm.$set(t, "supplement", $event.target.value)}}}):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }